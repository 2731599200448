import React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import { FormattedMessage } from 'react-intl';

import { StaticImage } from "gatsby-plugin-image"
const StyledLink = styled(LocalizedLink)`
opacity:1 !important;


`

import { useLightbox } from 'simple-react-lightbox'
import styled from '@emotion/styled'
import LocalizedLink from '../int/LocalizedLink'
import { fonts } from '../../styles/variables'
import { LightboxImg1 } from '../swipesliders/img/HomeSliderImg'
import { MdBurstMode } from 'react-icons/md'
// USE THE IMPORT BELOW INSTEAD IF YOU ARE USING THE PRO VERSION
// import { useLightbox } from 'simple-react-lightbox-pro'

const imgPaths = [
 "../../../../content/booking/studio/V7_Studio_001.jpg",
]



const Div = styled.div`
`


const ThumbWrapper = styled.div`


.gatsby-image-wrapper-constrained {
width:100%;
height:auto;
display:none;

   @media screen and (max-width: 959px) {
display:block;

   }

}

`

const PlxWrapper = styled.div`


.gatsby-image-wrapper-constrained {
height:460px;

  @media screen and (max-width: 1000px) {
height:440px;

   }

}

`








export function StudioPlxImg() {
  return (
    <ThumbWrapper>
      <StaticImage
        src="../../content/rooms/V7_091_Buchen.jpg"
        alt={""}
        placeholder="blurred"
        layout="constrained"
        quality="100"
      />

    </ThumbWrapper>
  )
}


export function StudioPlusPlxImg() {
  return (
    <ThumbWrapper>
      <StaticImage
        src="../../content/rooms/V7_093_Buchen.jpg"
        alt={""}
        placeholder="blurred"
        layout="constrained"
        quality="100"
      />

    </ThumbWrapper>
  )
}


export function SuitePlxImg() {
  return (
    <ThumbWrapper>
      <StaticImage
        src="../../content/rooms/V7_092_Buchen.jpg"
        alt={""}
        placeholder="blurred"
        layout="constrained"
        quality="100"
      />

    </ThumbWrapper>
  )
}


